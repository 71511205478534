import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

export default function MainTemplate(props) {


    return (
        <Wrapper>
            <InnerPlaceholder>
                {props.children}
            </InnerPlaceholder>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  padding: 10px;
  padding-top: 30px;
  color: white;
  background: black;
  min-height: 100vh;
`;

const InnerPlaceholder = styled.div`
  width: 1020px;
  box-sizing: border-box;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;