import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import MainTemplate from "../templates/MainTemplate";
import UnderConstructionTool from "../tools/UnderConstructionTool";

export default function Feedback(props) {

    return (
        <MainTemplate active={'feedback'}>
            <Wrapper>

                <p>
                    If you have any ideas how we can improve the app or service please let us know at <a style={{color: 'white'}} href={'mailto:hello@cardiomood.com'} >hello@cardiomood.com</a>
                </p>


            </Wrapper>
        </MainTemplate>
    );
}

const Wrapper = styled.div`
  
`;

const H1 = styled.h1`
  font-size: 24px;
`;


const H2 = styled.h2`
  font-size: 18px;
`;
