import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import MainTemplate from "../templates/MainTemplate";

import ch1Img from '../../assets/images/Charger1.png'
import ch2Img from '../../assets/images/Charger1.png'

import pairing1Img from '../../assets/images/pairing1.jpg'
import pairing2Img from '../../assets/images/pairing2.jpg'
import pairing3Img from '../../assets/images/pairing3.jpg'
import pairing4Img from '../../assets/images/pairing4.jpg'

export default function PairingHelp(props) {

    return (
        <MainTemplate active={'pairing-help'}>
            <Wrapper>
                <MainHeading>
                    PAIRING
                </MainHeading>
                <p>
                    The connection between the measurement bracelet and your smart device is established via Bluetooth.
                    Therefore, it is necessary to activate Bluetooth on your smartphone (please refer to the instruction
                    manual of your mobile phone).
                </p>
                <p>
                    The bracelet was sent to you in shipping mode. To wake-up the bracelet, please put it on the charger
                    for 2 hours.
                </p>

                <ImagesRow>
                    <Img2Placeholder>
                        <Img2 src={ch1Img}/>
                    </Img2Placeholder>
                    <Img2Placeholder>
                        <Img2 src={ch2Img}/>
                    </Img2Placeholder>

                </ImagesRow>

                <ol>
                    <li>
                        Attach magnetic cable to back
                    </li>
                    <li>
                        Plug USB Cable in charger
                    </li>
                    <li>
                        Green PPG LED on back starts to blink
                    </li>
                    <li>
                        Green LED next to pusher starts blinking
                    </li>
                    <li>
                        Full when LED next to pusher is continously green
                    </li>

                </ol>

                <p>
                    The polarity of the magnets in the bracelet and the charger will ensure that the charger contacts
                    will align.
                </p>

                <p>
                    Please start pairing and follow the instructions.
                </p>

                <Row4>
                    {[
                        {
                            text: 'Once the device is found, please confirm by pressing the button. On successful pairing, the bracelet will light the orange LED up for 10 seconds and then turn it OFF.',
                            img: pairing1Img
                        },
                        {
                            text: 'The app will display the Serial Number found. Please double check the S/N on the device and click Confirm.',
                            img: pairing2Img
                        },
                        {
                            text: 'You will be reminded to type 999999 (six times 9) on the Bluetooth pairing screen',
                            img: pairing3Img
                        },
                        {
                            text: 'Please type 999999 and press Pair',
                            img: pairing4Img
                        }
                    ].map((im, i) => {
                        return (
                            <Col key={i}>
                                <ColImg src={im.img}/>
                                <ColText>
                                    {im.text}
                                </ColText>
                            </Col>
                        )
                    })}
                </Row4>

            </Wrapper>
        </MainTemplate>
    );
}

const ColImg = styled.img`
  width: 100%;
`;

const ColText = styled.div`
  margin-top: 20px;
  line-height: 24px;
`;

const Row4 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @media (max-width: 720px) {
    display: block;
  }
`;

const Col = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: 30px;
  @media (max-width: 720px) {
    width: 100%;
  }
`;

const Img2 = styled.img`
  width: 100%;
`;

const Img2Placeholder = styled.div`
  flex: 1;
  margin: 10px;
  @media (max-width: 720px) {
    flex: auto;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
`;

const ImagesRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (max-width: 720px) {
    display: block;
  }
`;

const MainHeading = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
`;

const Wrapper = styled.div`
  line-height: 24px;
`;