import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import MainTemplate from "../templates/MainTemplate";
import UnderConstructionTool from "../tools/UnderConstructionTool";

export default function CallUs(props) {

    return (
        <MainTemplate active={'call-us'}>
            <Wrapper>
                <H1>
                    CONTACT US
                </H1>
                <p>
                    CardioMood SA <br/>
                    Chemin du Pré-Fleuri 5 <br/>
                    1228 Plan-les-Ouates <br/>
                    Geneva <br/>
                    Switzerland
                </p>
                <p>
                    Email: support@cardiomood.com
                </p>
                <p>
                    Please contact CardioMood if you need support for your bracelet or app. Please provide in the email
                    information about your bracelet and app - the version of the app and the Firmware.
                </p>
                <p>
                    Please also use "APP LOG" button that will send logs attached to the email, this logs will help us
                    to understand the issue.
                </p>

            </Wrapper>
        </MainTemplate>
    );
}

const Wrapper = styled.div`

`;

const H1 = styled.h1`
  font-size: 24px;
`;


const H2 = styled.h2`
  font-size: 18px;
`;
