import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import MainTemplate from "../templates/MainTemplate";
import UnderConstructionTool from "../tools/UnderConstructionTool";

export default function TermsOfService(props) {

    return (
        <MainTemplate active={'terms-of-service'}>
            <Wrapper>

                <H1>
                    Terms of Service
                </H1>

                <p>
                    Effective Date: Oct 23, 2016
                </p>

                <p>
                    CardioMood SA (“CardioMood”, “our”, “us” or “we”) provides the CardioMood website and application.
                </p>

                <p>
                    The following terms and conditions govern your use of the https://cardiomood.com/ website (the “Website”), all content, services and products available at or through the Website, including, but not limited to the CardioMood hosting services, APIs, libraries, widgets, plugins, modules, and any Upgrades (collectively with the Website referred to as the “Services”). The Services are owned and operated by CardioMood.
                </p>

                <p>
                    The Website and Services are offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other operating rules, policies (including, without limitation, CardioMood's Privacy Policy) and procedures that may be published from time to time on this Site by CardioMood (collectively, the “Agreement”).
                </p>

                <p>
                    Please read this Agreement carefully before accessing or using the Services. By accessing or using any part of the web site, you agree to become bound by the terms and conditions of this Agreement. If you do not agree to all the terms and conditions of this Agreement, then you may not access the Website or use any Services. If these terms and conditions are considered an offer by CardioMood, acceptance is expressly limited to these terms. The Services is available only to individuals who are at least 13 years old (16 years for individuals from EU).
                </p>

                <H2>
                    Your CardioMood Account and Site
                </H2>

                <p>
                    If you create an account on the Services, you are responsible for maintaining the security of your account, account credentials and account content, and you are fully responsible for all activities that occur under the account and any other actions taken in connection with the Services. You must not create more than one account per every website served by the Services. You must not describe or assign content to your account in a misleading or unlawful manner, including in a manner intended to trade on the name or reputation of others, and CardioMood may change or remove any content or files that it considers inappropriate or unlawful, or otherwise likely to cause CardioMood liability. You must immediately notify CardioMood of any unauthorized uses of your account or any other breaches of security. CardioMood will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions.
                </p>

                <H2>
                    Responsibility of Users and Contributors
                </H2>

                <p>
                    If you operate an account, post content or files to the Services, or otherwise make (or allow any third party to make) any material or information available by means of the Services (any such material, “Content”), you are entirely responsible for the content of, and any harm resulting from, that Content. By making Content available, you represent and warrant that:
                </p>

                <ul>
                    <li>
                        You (or any third party on your behalf) shall be using any functionality of the Services for their intended purposes only (e.g. not use the content delivery network Services functionality as CDN origin);
                    </li>

                    <li>
                        You shall not use the Services, Upgrades or Paid Services, through a vulnerability or otherwise, in a way that prevents the Services from tracking your usage, generating invoices or charging you through your credit card or other payment mechanism;
                    </li>

                    <li>
                        You shall be responsible for using commercially reasonable efforts to prevent unauthorized access to or use of the Services;
                    </li>

                    <li>
                        You shall promptly notify CardioMood of any unauthorized access or use of the Services;
                    </li>

                    <li>
                        You shall not reverse engineer, decompile, translate, disassemble or otherwise attempt to extract any or all of the proprietary source code of the Services;
                    </li>

                    <li>
                        You shall not alter, remove or obscure any copyright, trademark or other proprietary notices or confidentiality legend on the Services;
                    </li>

                    <li>
                        The downloading, copying and use of the Content will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark or trade secret rights, of CardioMood or any third party;
                    </li>

                    <li>
                        If any third party has rigths to intellectual property you create, you have either (i) received permission from such third party to post or make available the Content, including but not limited to any software, or (ii) secured from the third party a waiver as to all rights in or to the Content;
                    </li>

                    <li>
                        You have fully complied with any third-party licenses relating to the Content, and have done all things necessary to successfully pass through to end users any required terms;
                    </li>

                    <li>
                        The Content does not contain or install any viruses, worms, malware, trojan horses or other harmful or destructive content or other item that may remove or change any content of the Services or attempt to circumvent security or interfere with the proper working of the Services or the servers on which it is hosted;
                    </li>

                    <li>
                        The Content is not spam and does not contain unethical content or content designed to be used as a part of any unlawful acts (such as phishing) or mislead recipients as to the source of the material (such as spoofing);
                    </li>

                    <li>
                        The Content is not pornographic, does not contain threats or incite violence towards individuals or entities, and does not violate the privacy or publicity rights of any third party;
                    </li>

                    <li>
                        You have, in the case of Content that includes computer code, accurately categorized and/or described the type, nature, uses and effects of the materials, whether requested to do so by CardioMood or otherwise; and
                    </li>

                    <li>
                        Your Content and usage of the Services does not violate applicable laws and governmental regulations.
                    </li>

                </ul>

                <H2>
                    Paid Services
                </H2>
                <p>
                    Fees; Payment. You can sign up for paid Services provided by us (“Paid Services”). By signing up for a Paid Services account you agree to pay CardioMood the monthly fees  indicated at https://cardiomood.com/ or as indicated in your Order Form. By signing up for a Paid Services account you agree to pay CardioMood monthly Subscription Fees.
                </p>

                <p>
                    Refund, Change and Cancellation of Services. Paid Services fees are not refundable. CardioMood reserves the right to change the payment terms and fees upon thirty days prior written notice to you. Paid Services may be canceled by you at any time through your account settings page, subject to the terms of this Agreement.
                </p>

                <p>
                    Discount. CardioMood may offer you, at its sole discretion, Paid Services at a discounted rate (the “Discounted Services”) if you commit to subscribe to the Services for an annual or longer subscription period (the “Discount Subscription Period”) or as indicated in your Order Form. Payments will be charged in advance on the day you sign up for a Discounted Service and will cover the use of that service for a monthly or annual subscription period as indicated.
                </p>

                <p>
                    Invoice. Applicable fees for Paid Services or Discounted Services, as the case may be, will be invoiced on the day you sign up for a Service and shall be due in advance of using such Services as provided in the Order Form. If any invoiced amount based on any combination of Subscription Fees and Usage Fees is not received by CardioMood by the due date, then without limiting CardioMood’s rights or remedies: (a) CardioMood may suspend your access to the Website and/or Services until the invoiced amount will be received through your credit card or other payment mechanism; (b) those charges may accrue late interest at the rate of 1.5% of the outstanding balance per month, or the maximum rate permitted by law, whichever is lower; (c) CardioMood may condition future subscription renewals on shorter payment terms; and (d) CardioMood may accelerate your unpaid fee obligations to become immediately due and payable, and suspend the provision of Services to you until the overdue amounts are paid in full.
                </p>

                <p>
                    Automatic Renewal. Unless you notify CardioMood before the end of the applicable subscription period that you want to cancel a Paid Service or Discounted Service, your subscription will automatically renew and you authorize CardioMood, or a third party through which you transact (without notice to you, unless required by applicable law) to collect the then-applicable annual or monthly subscription fee for such Service (as well as any taxes) using any credit card or other payment mechanism we have on record for you. If all credit cards we have on file for you or any other payment method used by you are declined for payment of your membership or subscription fees, CardioMood may suspend or cancel the provision of Services to you, as applicable, unless you provide us with a new credit card or another payment mechanism. If you provide us with a new credit card and are successfully charged before your subscription is cancelled, your new subscription period will be based on the original renewal date and not the date of the successful charge.
                </p>

                <p>
                    Support. Certain Paid Services packages include access to priority email and telephone support. “Email support” means the ability to make requests for technical support assistance by email at any time (with reasonable efforts by CardioMood to respond within one business day) concerning the use of the Paid Services. “Priority” means that support for Paid Services customers takes priority over support for users of the standard, free CardioMood Services. All Paid Services support will be provided in accordance with CardioMood standard Paid Services practices, procedures and policies.
                </p>

                <p>
                    Order Form. In addition to the terms and conditions of this Agreement, the provision of Paid Services and Discounted Services and your obligations relating to the same will be subjected to the terms and conditions of any applicable order form provided by CardioMood to you (the “Order Form”). In terms of any conflict between the provisions of this Agreement and the Order Form, the provisions of this Agreement shall prevail except where the Order Form expressly provides that it shall prevail over a provision of the Agreement.
                </p>

                <p>
                    Taxes. All fees for Services and any Upgrades (as defined below) are exclusive of taxes, levies, duties or charges imposed by government authorities (collectively, “Taxes”). You shall be solely responsible for all sales, service, value-added, use, excise, consumption and any other Taxes on amounts payable by Customer under the Order Forms and this Agreement (other than any Taxes on CardioMood’s income, revenues, gross receipts, personnel or assets). Without limiting the foregoing, if you are required to deduct or withhold any Taxes under applicable laws outside the United States, you shall remit such Taxes in accordance with those applicable laws and all fees payable shall be increased so that CardioMood receives an amount equal to the sum it would have received had no withholding or deduction been made. If an applicable tax authority requires CardioMood to pay any taxes that should have been payable by you, CardioMood will advise you in writing, and you will promptly reimburse CardioMood for the amounts paid.
                </p>

                <H2>
                    Upgrades
                </H2>

                <ul>
                    <li>
                        General Terms. Optional Paid Services (including “Service Upgrades” and “Usage Upgrades”, collectively, “Upgrades”) are available through the use of the Services. By selecting an Upgrade you agree to pay CardioMood the monthly or annual subscription fees indicated for that service. Payments will be charged on a pre-pay basis on the day you sign up for an Upgrade and will cover the use of that service for a monthly or annual subscription period as indicated. Upgrade fees are not refundable.
                    </li>
                    <li>
                        Automatic Renewal. Unless you notify CardioMood before the end of the applicable subscription period that you want to cancel an Upgrade, your Upgrade subscription will automatically renew and you authorize us to collect the then-applicable annual or monthly subscription fee for such Upgrade (as well as any taxes) using any credit card or other payment mechanism we have on record for you. Upgrades can be canceled at any time by notifying CardioMood through the means of the Services dashboard.
                    </li>
                </ul>

                <H2>
                    Mailing List
                </H2>

                <p>
                    You can unsubscribe from our mailing list at any time directly from the unsubscribe link included in each electronic marketing message we send to you. If you do so, we will promptly update our databases, and will take all reasonable steps to meet your request at the earliest possible opportunity, but we may continue to contact you to the extent necessary for the purposes of providing our Services.
                </p>

                <p>
                    When providing CardioMood with your personal information, the processing of your personal information is governed by the CardioMood's Privacy Policy. CardioMood reserves the right to contact you personally for the means of improving the Services.
                </p>

                <H2>
                    Changes
                </H2>

                <p>
                    CardioMood reserves the right, at its sole discretion, to modify or replace any part of this Agreement. Except as expressly set, such changes will be effective after three days upon posting the new version of Agreement on https://cardiomood.com/terms/. It is your responsibility to check this https://cardiomood.com/terms/ periodically for changes. Your continued use of or access to the Website or Services following the posting of any changes to this Agreement constitutes acceptance of those changes. CardioMood may also, in the future, offer new services and/or features through the Website (including, the release of new tools and resources). Such new features and/or services shall be subject to the terms and conditions of this Agreement.
                </p>

                <H2>
                    Termination
                </H2>

                <ul>
                    <li>
                        Termination Rights for Services — Excluding Paid or Discounted Services or Upgrades. CardioMood may terminate your access to all or any part of the Services, at any time, at its own discretion, with or without notice, effective immediately. Subject to the terms of this Agreement, if you wish to terminate this Agreement or your CardioMood account (if you have one), you may simply discontinue using the Services.
                    </li>

                    <li>
                        Termination for Cause — Paid or Discounted Services or Upgrades. In addition to CardioMood’s right to terminate this Agreement under the Agreement, you or CardioMood may terminate the Agreement for cause (a) upon 30 days’ written notice to the other of a material breach if the breach remains uncured at the expiration of the notice period or (b) if the other party (i) becomes the subject of a proceeding relating to insolvency, receivership, liquidation or assignment for the benefit of creditors to the extent permitted by applicable laws or governmental regulations, (ii) goes out of business or (iii) ceases its operations; provided that, CardioMood may terminate the Services immediately as part of a general shut down of our service.
                    </li>

                    <li>
                        Cancellation of Discounted Services. Except in the event of termination for cause as in section 15(b) above, Discounted Services are (a) non-cancellable; and (b) cannot be decreased for the first six (6) months of the Discount Subscription Period. In the event that you choose to cancel or decrease the Discounted service after six months but before the end of the Discount Subscription Period, you shall pay CardioMood cancellation charges equal to the discount granted you by CardioMood for committing to a Discount Subscription Period. You authorize us to collect the applicable cancellation charges (as well as any taxes) using any credit card or other payment mechanism we have on record for you. Cancellation of Discounted Services may be effected through your account settings page and is subject to the terms of this Agreement. All fees paid for commitments to a Discount Subscription Period are not refundable.
                    </li>

                    <li>
                        Effect of Termination. Upon termination of this Agreement, your rights under these Agreement will automatically terminate. Accordingly, upon termination, your right to use the Services will immediately cease and you will lose all data related to your account. All fees that were due prior to the termination of the Agreement and Services shall remain due and become immediately payable. In such an event, you must cease all use of the Services. Our failure to insist upon or enforce your strict compliance with these Agreement will not constitute a waiver of any of our rights. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, confidentiality, payment obligations and limitations of liability.
                    </li>

                </ul>

                <H2>
                    Confidentiality
                </H2>

                <ul>
                    <li>
                        Confidential Information. “Confidential Information” means all information disclosed by a party (“Disclosing Party”) to the other party (“Receiving Party”), whether orally or in writing, that is designated as confidential or, given the nature of the information and the circumstances of disclosure, should be understood to be confidential. Your Confidential Information includes the Content; CardioMood’s Confidential Information includes the Services and any documentation related thereto; and Confidential Information of each party includes but is not limited to the terms and conditions of this Agreement and all Order Forms, including pricing, business and marketing plans, technology and technical information, product plans and designs, and business processes disclosed by each party in connection with this Agreement. Confidential Information does not include information that: (a) is at the time of disclosure, or later becomes, generally known to the public through no fault of Receiving Party; (b) was known to the Receiving Party with no obligation of confidentiality prior to disclosure by Disclosing Party, as proven by records of Receiving Party; (c) is disclosed to Receiving Party by a third party who did not directly or indirectly obtain the information subject to any confidentiality obligation; or (d) is at any time independently developed by Receiving Party without use of Disclosing Party’s Confidential Information as proven by records of Receiving Party.
                    </li>
                    <li>
                        Confidentiality Obligation. Except as provided in Section 16(c) below, the Receiving Party shall not disclose or otherwise make available any Confidential Information of Disclosing Party to anyone except those of its employees, directors, attorneys, agents and consultants who: (a) need to know the Confidential Information in connection with the purpose of the Agreement and (b) who have previously agreed to be bound by confidentiality obligations no less stringent than those in the Agreement. Each party shall (x) safeguard all Confidential Information of the other party with at least the same degree of care (but no less than reasonable care) as it uses to safeguard its own confidential information and (y) not use any Confidential Information of the other party for any purpose outside the scope of the Agreement. In the event of any breach or threatened breach by the Receiving Party of its obligations under this Section 16, the Disclosing Party will be entitled to seek injunctive and other equitable relief to enforce such obligations.
                    </li>
                    <li>
                        Disclosure. If Receiving Party is compelled by law to disclose Confidential Information of Disclosing Party, then to the extent legally permitted, Receiving Party shall provide Disclosing Party with prior notice of the compelled disclosure and reasonable assistance, at Disclosing Party’s cost, if Disclosing Party wishes to contest the compelled disclosure. Any compelled disclosure shall be limited to the extent required, and shall be subject to confidentiality protections to the extent practicable. If Receiving Party is compelled by law to disclose Disclosing Party’s Confidential Information as part of a civil proceeding to which Disclosing Party is a party, and Disclosing Party is not contesting the disclosure, Disclosing Party will reimburse Receiving Party for its reasonable cost of compiling and providing secure access to that Confidential Information.
                    </li>

                </ul>

                <H2>
                    Disclaimer of Warranties
                </H2>

                <p>
                    THE SERVICES, WEBSITE AND ANY AND ALL SERVER AND NETWORK COMPONENTS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR BAILMENT OF YOUR DATA ON OUR SERVERS OR SECURITY ASSOCIATED WITH THE TRANSMISSION OF INFORMATION TO US OR VIA THE SERVICES. YOU ALSO ACKNOWLEDGE THAT WE DO NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR VIRUS-FREE, THAT DEFECTS WILL BE CORRECTED, NOR DO WE MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICES, AND NO INFORMATION, ADVICE OR SERVICES OBTAINED BY YOU FROM US OR THROUGH THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT.
                </p>

                <p>
                    YOU ACKNOWLEDGE THAT YOUR USE OF THE SERVICES AND WEBSITE IS AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DO NOT WARRANT THAT YOUR USE OF THE SERVICES AND WEBSITE IS LAWFUL IN ANY PARTICULAR JURISDICTION, AND WE SPECIFICALLY DISCLAIM SUCH WARRANTIES. BY ACCESSING OR USING THE SERVICES OR WEBSITE YOU REPRESENT AND WARRANT THAT YOUR ACTIVITIES ARE LAWFUL IN EVERY JURISDICTION WHERE YOU ACCESS OR USE THE SERVICES.
                </p>

                <p>
                    TO THE EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, IN NO EVENT SHALL UPLOADCARE, OR ITS SUBSIDIARIES OR ANY OF THEIR SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES OR LICENSORS BE LIABLE (JOINTLY OR SEVERALLY) TO YOU FOR PERSONAL INJURY OR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER.
                </p>

                <p>
                    SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES. THEREFORE, SOME OF THE ABOVE LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU. NOTHING IN THIS AGREEMENT SHALL AFFECT ANY NON-WAIVABLE STATUTORY RIGHTS THAT APPLY TO YOU. THIS SECTION SHALL SURVIVE ANY EXPIRATION OR TERMINATION OF YOUR RELATIONSHIP WITH UPLOADCARE.
                </p>

                <H2>
                    Limitation of Liability
                </H2>

                <p>
                    In no event will CardioMood, or its suppliers or licensors, be liable with respect to any subject matter of this agreement under any contract, negligence, strict liability or other legal or equitable theory for: (i) any special, incidental or consequential damages; (ii) the cost of procurement for substitute products or services; (iii) for interruption of use or loss or corruption of data; or (iv) for any amounts that exceed the fees paid by you to CardioMood under this Agreement during the twelve (12) month period prior to the cause of action on the Order Form from which the cause of action arose. CardioMood shall have no liability for any failure or delay due to matters beyond their reasonable control. The foregoing shall not apply to the extent prohibited by applicable law.
                </p>

                <p>
                    IN NO EVENT SHALL UPLOADCARE BE LIABLE FOR ANY INDIRECT, ECONOMIC, SPECIAL, INCIDENTAL OR CONSEQUENTIAL LOSSES OR DAMAGES RELATED TO: (A) THE SERVICES; (B) THE UPLOADCARE WEBSITE; (C) YOUR CONTENT; (D) YOUR USE OF, INABILITY TO USE, OR THE PERFORMANCE OF THE WEBSITE OR SERVICES; (E) ACTION TAKEN IN CONNECTION WITH AN INVESTIGATION BY UPLOADCARE OR LAW ENFORCEMENT AUTHORITIES REGARDING YOUR USE OF THE WEBSITE OR SERVICES; (F) ACTION TAKEN IN CONNECTION WITH COPYRIGHT OR OTHER INTELLECTUAL PROPERTY OWNERS; (G) ANY ERRORS OR OMISSIONS IN THE SERVICES' or WEBSITE’S TECHNICAL OPERATION; OR (H) ANY DAMAGE THAT RESULTS FROM EVENTS BEYOND OUR REASONABLE CONTROL, SUCH AS DAMAGES TO ANY YOUR COMPUTER, MOBILE DEVICE, OR OTHER EQUIPMENT OR TECHNOLOGY INCLUDING, WITHOUT LIMITATION, DAMAGE FROM ANY SECURITY BREACH OR FROM ANY VIRUS, BUGS, TAMPERING, FRAUD, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER LINE OR NETWORK FAILURE OR ANY OTHER TECHNICAL OR OTHER MALFUNCTION, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, LOSS OF DATA, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, EVEN IF FORESEEABLE OR EVEN IF UPLOADCARE HAS BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL UPLOADCARE’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OR ACTION EXCEED THE AMOUNTS PAID BY YOU, IF ANY, TO UPLOADCARE IN THE PAST TWELVE MONTHS. THE PRIOR LIMITATION ON DAMAGES IS NOT INTENDED TO LIMIT UPLOADCARE’S OBLIGATION TO PAY PREVAILING PARTY COSTS OR FEES IF RECOVERABLE PURSUANT TO APPLICABLE LAW. THE LIMITATIONS SET FORTH IN THIS SECTION WILL NOT LIMIT OR EXCLUDE UPLOADCARE’S LIABILITY FOR UPLOADCARE’S GROSS NEGLIGENCE, FRAUD OR INTENTIONAL, WILLFUL, MALICIOUS OR RECKLESS MISCONDUCT. THIS SECTION SHALL SURVIVE ANY EXPIRATION OR TERMINATION OF YOUR RELATIONSHIP WITH UPLOADCARE.
                </p>

                <p>
                    YOU AGREE THAT IN THE EVENT YOU INCUR ANY DAMAGES, LOSSES OR INJURIES THAT ARISE OUT OF UPLOADCARE’S ACTS OR OMISSIONS, THE DAMAGES, IF ANY, CAUSED TO YOU ARE NOT IRREPARABLE OR SUFFICIENT TO ENTITLE YOU TO AN INJUNCTION PREVENTING ANY EXPLOITATION OF ANY WEB SITE, PROPERTY, PRODUCT, SERVICE, OR OTHER UPLOADCARE CONTENT OWNED OR CONTROLLED BY UPLOADCARE, AND YOU WILL HAVE NO RIGHTS TO ENJOIN OR RESTRAIN THE DEVELOPMENT, PRODUCTION, DISTRIBUTION, ADVERTISING, EXHIBITION OR EXPLOITATION OF ANY WEBSITE, PROPERTY, PRODUCT, SERVICE, OR OTHER UPLOADCARE CONTENT OWNED OR CONTROLLED BY UPLOADCARE.
                </p>

                <p>
                    BY ACCESSING THE SERVICES, YOU UNDERSTAND THAT YOU MAY BE WAIVING RIGHTS WITH RESPECT TO CLAIMS THAT ARE AT THIS TIME UNKNOWN OR UNSUSPECTED, AND IN ACCORDANCE WITH SUCH WAIVER, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD, AND HEREBY EXPRESSLY WAIVE, THE BENEFITS OF SECTION 1542 OF THE CIVIL CODE OF CALIFORNIA, AND ANY SIMILAR LAW OF ANY STATE OR TERRITORY, WHICH PROVIDES AS FOLLOWS: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR." THE OBLIGATIONS UNDER THIS SECTION SHALL SURVIVE ANY TERMINATION OR EXPIRATION OF THIS AGREEMENT OR YOUR USE OF THE SERVICES.
                </p>

                <H2>
                    Privacy Policy
                </H2>

                <p>
                    CardioMood will process your information or data including personal information in accordance with CardioMood’s Privacy Policy available at https://cardiomood.com/privacy_policy/.
                </p>

                <H2>
                    OFAC Compliance
                </H2>

                <p>
                    The Services may be subject to export control and economic sanctions laws and regulations administered or enforced by the United States Department of Commerce, Department of Treasury's Office of Foreign Assets Control (“OFAC”), Department of State, and other United States authorities (collectively, “U.S. Trade Laws”). You may not use the Services to export or re-export, or permit the export or re-export, of software or technical data in violation of U.S. Trade Laws. In addition, by using the Services, you represent and warrant that you are not (a) an individual, organization or entity organized or located in a country or territory that is the target of OFAC sanctions (including Cuba, Iran, Syria, Sudan, North Korea, or the Crimea region of Ukraine); (b) designated as a Specially Designated National or Blocked Person by OFAC or otherwise owned, controlled, or acting on behalf of such a person; (c) otherwise a prohibited party under U.S. Trade Laws; or (d) engaged in nuclear, missile, chemical or biological weapons activities to which U.S. persons may not contribute without a U.S. Government license. Unless otherwise provided with explicit written permission, CardioMood also does not register, and prohibits the use of any of our Services in connection with, any Country-Code Top Level Domain Name (“ccTLD”) for any country or territory that is the target of OFAC sanctions. The obligations under this section shall survive any termination or expiration of this Agreement or your use of the Services.
                </p>

                <H2>
                    HIPAA Compliance
                </H2>

                <p>
                    The use of the Services implies that you will not store, transmit or otherwise process any content or files that fall within the definition of “Protected Health Information” under the HIPAA Privacy Rule (45 C.F.R. Section 164.051), unless User and CardioMood Inc. separately enter into a HIPAA Business Associate Agreement ("BAA"), which may be done by sending an inquiry to help@cardiomood.com.
                </p>

                <p>
                    Otherwise, Users are solely responsible for compliance with all applicable laws governing the privacy and security of personal data, including medical or other sensitive data if any. We specifically disclaim any representation or warranty that the Services without entering into a BAA, as offered, comply with HIPAA. Storing and permitting access to “Protected Health Information” without entering in a BAA is a material violation of this Agreement, and grounds for immediate account termination.
                </p>

                <p>
                    If you have questions about handling “Protected Health Information” via the Services, please contact us at privacy@cardiomood.com.
                </p>

                <H2>
                    Force Majeure
                </H2>

                <p>
                    Neither party shall be liable or responsible to the other party, nor be deemed to have defaulted under or breached this Agreement, for any failure or delay in fulfilling or performing any term of this Agreement (except for any obligations to make payments), when and to the extent such failure or delay is caused by any act beyond the party’s reasonable control, including but not limited to, acts of God; flood, fire or explosion; war, terrorism, invasion, riot or other civil unrest; epidemic, pandemic or similar outbreak; embargoes or blockades in effect on or after the date of this Agreement; governmental or regulatory action; or national or regional emergency (each of the foregoing, a “Force Majeure Event”), in each case, provided that the affected party uses diligent efforts to end the failure or delay and minimize the effects of such Force Majeure Event.
                </p>


                <H2>
                    Relationship of Parties
                </H2>

                <p>
                    The parties expressly understand and agree that their relationship is that of independent contractors. Nothing in this Agreement shall constitute one party as an employee, agent, joint venture partner or servant of another. This Agreement is for the sole benefit of the parties hereto and their respective successors and permitted assigns and nothing herein, express or implied, is intended to or shall confer on any other person any legal or equitable right, benefit or remedy of any nature whatsoever under or by reason of this Agreement.
                </p>

                <H2>
                    Notice
                </H2>

                <p>
                    You agree to transact with us electronically. Your affirmative act of using the Services constitutes your acceptance signature to these Agreement. We may provide notices to you electronically (1) via email if you have provided us with a valid email address or (2) by posting the notice on a website designated by us for this purpose. The delivery of any notice is effective when sent or posted by us, regardless of whether you read the notice or actually receive delivery. You can withdraw your consent to receive notices electronically by discontinuing your use of the Service.
                </p>

                <p>
                    You can contact CardioMood at help@cardiomood.com, or at:
                </p>

                <p>
                    CardioMood SA <br/>
                    Chemin du Pré-Fleuri 5 <br/>
                    1228 Plan-les-Ouates <br/>
                    Geneva <br/>
                    Switzerland
                </p>
                <p>
                    Notices required or permitted to be given to CardioMood under this Agreement shall be in writing and shall be deemed to be sufficiently given: (i) one business day after being sent by overnight courier; (ii) three business days after being sent by registered mail, return receipt requested; or (iii) one business day after being sent by email (provided that you do not receive a response that the message could not be delivered or an out-of-office reply). You shall provide immediate notice to CardioMood in the event you change your contact details. Either Party may change its address(es) for notice by providing notice to the other in accordance with this section.
                </p>

                <H2>
                    Governing Law and Dispute Resolution
                </H2>

                <p>
                    Except to the extent applicable law, if any, provides otherwise, this Agreement, any access to or use of the Services will be governed by the laws  of Geneva, Switzerland, excluding its conflict of law provisions. The parties agree that the UN Convention on Contracts for the International Sale of Goods shall not apply to the Agreement nor to any dispute or transaction arising out of the Agreement. Except for claims for injunctive or equitable relief or claims regarding intellectual property rights (which may be brought in any competent court without the posting of a bond), any dispute arising under this Agreement shall be finally settled in accordance with the Comprehensive Arbitration Rules of the Judicial Arbitration and Mediation Service, Inc. (“JAMS”) by three arbitrators appointed in accordance with such Rules. The arbitration shall take place in Geneva, Switzerland, in the English language and the arbitral decision may be enforced in any court. The prevailing party in any action or proceeding to enforce this Agreement shall be entitled to costs and attorneys’ fees.
                </p>
                <p>
                    Waiver of Jury Trial and Class or Consolidated Actions. YOU WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. YOU ARE INSTEAD CHOOSING TO HAVE CLAIMS AND DISPUTES RESOLVED BY ARBITRATION. IN ANY LITIGATION BETWEEN YOU AND UPLOADCARE OVER WHETHER TO VACATE OR ENFORCE AN ARBITRATION AWARD, YOU WAIVE ALL RIGHTS TO A JURY TRIAL, AND ELECT INSTEAD TO HAVE THE DISPUTE BE RESOLVED BY A JUDGE. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If, however, this waiver of class or consolidated actions is deemed invalid or unenforceable, neither you nor CardioMood is entitled to arbitration; instead all claims and disputes will be resolved in a court as set forth below.
                </p>
                <p>
                    In the event that the binding arbitration provision above is found not to apply to you or to a particular claim or dispute, either as a result of your decision to opt-out of the Agreement or a court order, you agree that any claim or dispute that has arisen or may arise between you and CardioMood must be resolved exclusively by a state or federal court located in Los Angeles, California. You and CardioMood agree to submit to the personal jurisdiction of the courts located within Los Angeles, California for the purpose of litigating all such claims or disputes.
                </p>

                <H2>
                    Miscellaneous
                </H2>

                <p>
                    This Agreement and any applicable Order Form constitutes the entire agreement between CardioMood and you concerning the subject matter hereof, and they may only be modified by a written amendment signed by an authorized executive of CardioMood, or by the posting by CardioMood of a revised version. If any part of this Agreement is held invalid or unenforceable, that part will be construed to reflect the parties’ original intent, and the remaining portions will remain in full force and effect. The failure of CardioMood to enforce any right or provision of this Agreement will not be deemed a waiver of such right or provision. A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof. Subject to prior written consent of CardioMood, you may assign your rights under this Agreement to any party that consents to, and agrees to be bound by, its terms and conditions; CardioMood may assign its rights under this Agreement without condition. This Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns.
                </p>

            </Wrapper>
        </MainTemplate>
    );
}

const Wrapper = styled.div`

`;

const H1 = styled.h1`
    font-size: 24px;
`;


const H2 = styled.h2`
    font-size: 18px;
`;

