import logo from './logo.svg';
import './App.css';

import styled from 'styled-components'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import CallUs from "./components/pages/CallUs";
import SignInSupport from "./components/pages/SignInSupport";
import SignUpSupport from "./components/pages/SignUpSupport";
import Chat from "./components/pages/Chat";
import Feedback from "./components/pages/Feedback";
import EmailPage from "./components/pages/EmailPage";
import TermsOfService from "./components/pages/TermsOfService";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import PairingHelp from "./components/pages/PairingHelp";
import PairingTroubleshoot from "./components/pages/PairingTroubleshoot";

function App() {
    return (
        <Router>

            <Switch>

                <Route path="/sign-in-support">
                    <SignInSupport/>
                </Route>

                <Route path="/sign-up-support">
                    <SignUpSupport/>
                </Route>

                <Route path="/chat">
                    <Chat/>
                </Route>


                <Route path="/call-us">
                    <CallUs/>
                </Route>

                <Route path="/contact-us">
                    <Chat/>
                </Route>


                <Route path="/feedback">
                    <Feedback/>
                </Route>

                <Route path="/email">
                    <EmailPage/>
                </Route>


                <Route path="/terms-of-service">
                    <TermsOfService/>
                </Route>


                <Route path="/privacy-policy">
                    <PrivacyPolicy/>
                </Route>


                <Route path="/pairing-help">
                    <PairingHelp/>
                </Route>

                <Route path="/pairing-troubleshoot">
                    <PairingHelp/>
                    {/*<PairingTroubleshoot/>*/}
                </Route>

            </Switch>

        </Router>
    );
}

const Wrapper = styled.div`

`;

export default App;
