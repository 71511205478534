import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import MainTemplate from "../templates/MainTemplate";
import UnderConstructionTool from "../tools/UnderConstructionTool";

export default function PrivacyPolicy(props) {

    return (
        <MainTemplate active={'privacy-policy'}>
            <Wrapper>
                <H1>
                    Privacy Policy
                </H1>

                <p>
                    This Privacy Policy applies to CardioMood SA, Chemin du Pré-Fleuri 5, 1228 Plan-les-Ouates, Geneva,
                    Switzerland, registered under the laws of Switzerland under number CH-660.1.832.016-0.
                </p>

                <p>
                    This Privacy Policy describes how CardioMood collects and uses the information, which may include
                    personal data, you provide on our websites: CardioMood ('Sites'). The Sites are jointly referred to
                    as
                    the 'Services.'
                </p>

                <p>
                    This Privacy Policy applies to owners, employees, representatives or other individuals acting on
                    behalf
                    of party to which CardioMood provides the Services. We act in our customers’ interest and are
                    transparent about the processing of any personal data.
                </p>

                <p>
                    “Personal data” refers to any information relating to an identifiable individual or their personal
                    identity.
                </p>

                <H2>
                    CONSENT
                </H2>

                <p>
                    In subscribing to our Services or filling in a contact form on our Sites, you agree and accept that
                    we
                    may gather, process, store and/or use the submitted personal data under the rules set forth below.
                </p>

                <p>
                    By giving your consent to us, you retain the right to have your personal data rectified and/or to be
                    erased.
                </p>

                <H2>
                    DATA COLLECTION
                </H2>

                <p>
                    To grant you access and to use our Services, CardioMood requests you to provide the following
                    information, some of which may be personal data:
                </p>

                <p>
                    <i>Contact details</i>: We collect your contact information such as first name and last name,
                    business
                    email address and address, job workplace and position, telephone number.
                </p>

                <p>
                    <i>Financial information</i>: We collect data necessary for payment, including for invoicing
                    purposes,
                    such as your billing details and credit card number.
                </p>

                <p>
                    <i>Other</i>: When communicating with CardioMood, CardioMood collects and processes written
                    communications: email and live chat sessions, for improving its Services and quality control, which
                    includes the usage of the collected communications for the handling of claims and fraud detection
                    purposes. Collected communications are kept for a limited amount of time and automatically deleted,
                    unless CardioMood has a legitimate interest to keep such communications for a longer period,
                    including
                    for fraud investigation and legal purposes.
                </p>

                <H2>
                    DATA WE COLLECT ON SITES AUTOMATICALLY
                </H2>

                <p>
                    When using the Services, CardioMood also collects information automatically, some of which may be
                    personal data. This includes data such as:
                </p>

                <ul>
                    <li>
                        IP address
                    </li>
                    <li>
                        User agent (information about the browser type and version)
                    </li>
                    <li>
                        User subscription status
                    </li>
                </ul>

                <p>
                    CardioMood may also collect data automatically through cookies. For information on how we use
                    cookies,
                    refer to COOKIES AND TRACKING.
                </p>

                <H2>
                    DATA PROCESSING PURPOSES
                </H2>

                <p>
                    We use the information you provide, some of which may be personal data, for the following purposes:
                </p>

                <p>
                    A. Registration and account administration: We use the information to register your property and to
                    allow you and CardioMood to administer and manage the Services offered to you.
                </p>

                <p>
                    B. Providing our Services: We use the information, which may include personal data, to provide our
                    Services, facilitate their performance, improve the Services and Sites' content related to their
                    usage.
                </p>

                <p>
                    С. Analyze the use of Services: We use the information, which may include personal data, to analyze
                    the
                    volume and history of your use of our Services. We use the derivatives of such analyses to conduct
                    monitoring and reporting of your use of our Services to comply with our Terms of Service ('ToS').
                </p>

                <p>
                    D. Customer Service: We use the information to provide you customer support and customer success
                    services, such as to: respond to your requests, questions and concerns; provide you with best
                    practices
                    to use the Services; engage in customer success communications when encountering unusual activities
                    in
                    usage of Services.
                </p>

                <p>
                    E. Marketing/Communications: We use the information to provide you with information that you
                    request, to
                    send our newsletter, marketing communications and updates about new products and services or other
                    news
                    or offers which we believe will be of interest to you. Where we use your personal data for direct
                    marketing purposes, such as newsletters and marketing communications on new products and services or
                    other offers which we believe will be of interest to you, we include an unsubscribe link that you
                    can
                    use if you don’t want us to send messages in the future. We may invite you to attend events we
                    believe
                    may be of interest to you. We may also use your personal data to invite you to participate in
                    referral
                    programs.
                </p>

                <p>
                    F. Analytics, improvements and research: We use the information which may include personal data to
                    conduct research and analysis. We may involve a third party to do this on our behalf. We may share
                    or
                    disclose the results of such research, including to third-parties and our affiliates, in anonymous,
                    aggregated form. We also use the information, which may include personal data, to assess the needs
                    of
                    your business, to determine suitable products and to improve our Services and marketing efforts. We
                    may
                    invite you to take part in surveys, such as market research, and to analyze how to improve your
                    experience, and the functionality and quality of our Services.
                </p>

                <p>

                </p>

                <p>
                    H. Security, fraud detection and prevention: We use the information, which may include personal
                    data, in
                    order to prevent fraud and other illegal or infringing activities when using our Sites as well as
                    our
                    Services. We also use this information to investigate and detect fraud. CardioMood can use personal
                    data
                    for risk assessment and security purposes, including the authentication of users. For these
                    purposes,
                    personal data may be shared with third parties, such as law enforcement authorities as permitted by
                    applicable law and external advisors.
                </p>

                <p>
                    I. Legal and compliance: In certain cases, CardioMood needs to use the information provided, which
                    may
                    include personal data, to handle and resolve legal disputes or complaints, for regulatory
                    investigations
                    and compliance, to enforce agreement(s) or to comply with lawful requests from law enforcement
                    insofar
                    as it is required by law.
                </p>

                <p>
                    If we use automated means to process personal data which produces legal effects or significantly
                    affects
                    you, we will implement suitable measures to safeguard you rights and freedoms, including the right
                    to
                    obtain human intervention.
                </p>

                <H2>
                    LEGAL BASE
                </H2>

                <p>
                    In view of purpose A to D, CardioMood relies on the legal basis that the processing of your personal
                    data is necessary for the performance of your agreement with CardioMood. If you do not provide the
                    requested information, CardioMood cannot register your property, allow you to use, administer, and
                    manage the Services, nor can we provide customer service to you.
                </p>

                <p>
                    In view of purposes E to I, CardioMood relies on its legitimate commercial business interest to
                    provide
                    its Services to you, to prevent fraud and to improve its Services. When using personal data to serve
                    our
                    commercial business interest, CardioMood will always balance your rights and interests in the
                    protection
                    of your information against CardioMood’s rights and interests. For purpose I, CardioMood relies also
                    where applicable on compliance with legal obligations (such as lawful law enforcement requests).
                    Where
                    needed under applicable law, CardioMood will obtain your consent prior to processing your personal
                    data
                    for direct marketing purposes.
                </p>

                <p>
                    If you wish to object to the processing set out under D to G and no opt-out mechanism is available
                    to
                    you directly (for instance in your account settings), to the extent applicable, please contact our
                    Data
                    Protection Officer at privacy@cardiomood.com.
                </p>


                <H2>
                    DATA SHARING
                </H2>

                <p>
                    We share your information, which may include personal data and location data, with third parties as permitted by law
                    and
                    as described below. We do not sell or rent your personal data.
                </p>

                <p>
                    Service Providers: We share your information with third party service providers to provide our
                    Services,
                    store data and/or maintain the Sites or conduct business on our behalf. These service providers
                    shall
                    process personal data only as instructed by and to provide the services to CardioMood.
                </p>
                <p>

                    Payment Providers and other Financial Institutions: To process payments between you and CardioMood
                    your
                    information, as relevant, is shared with payment providers and other financial institutions.
                </p>
                <p>
                    Compelled Disclosure: When legally required, strictly necessary for the performance of the Services,
                    or
                    to protect our rights, we disclose your information to governmental authorities including law
                    enforcement (subject to a lawful request), or in legal proceedings.
                </p>
                <p>
                    Sharing and Disclosure of Aggregate Data: We may share information in aggregate form and/or in a
                    form
                    which does not enable the recipient of such information to identify you, with third parties, for
                    example
                    for industry and demographic analysis.
                </p>
                <p>
                    In addition, CardioMood can disclose your personal data to third parties, if you (or your account
                    administrator acting on your behalf) requests or authorizes disclosure thereof.
                </p>

                <H2>
                    INTERNATIONAL DATA TRANSFERS
                </H2>

                <p>
                    The transmission of personal data as described in this Privacy Policy may include overseas transfers
                    of
                    personal data to countries whose data protection laws are not as comprehensive as those of the
                    countries
                    within the European Union. Where required by European law, CardioMood shall only transfer personal
                    data
                    to recipients offering an adequate level of data protection. In these situations, as may be
                    required, we
                    make contractual arrangements to ensure that your personal data is still protected in line with
                    European
                    standards. You can ask us to see a copy of these contractual agreements by contacting our Data
                    Protection Officer at privacy@cardiomood.com.
                </p>
                <p>
                    CardioMood complies with the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield
                    Frameworks as set forth by the U.S. Department of Commerce regarding the collection, use, and
                    retention
                    of personal information transferred from the European Union and Switzerland to the United States.
                    CardioMood has certified to the Department of Commerce that it adheres to the Privacy Shield
                    Principles.
                    If there is any conflict between the terms of this Privacy Policy and the Privacy Shield Principles,
                    the
                    Privacy Shield Principles shall govern. To learn more about the Privacy Shield program, and to view
                    our
                    certification, please visit privacyshield.gov. For more information, see our Privacy Shield Notice.
                </p>


                <H2>
                    THIRD PARTY DATA
                </H2>

                <p>
                    To provide its Services, CardioMood captures and stores information, which may include personal
                    data,
                    about you and your end-users uploading materials to CardioMood: (1) IP addresses; (2) request
                    headers;
                    (3) data submitted to the API of our Services.
                </p>
                <p>
                    You can recover, modify or delete your data from your CardioMood account at any time through the
                    dedicated procedures provided within its Services.
                </p>
                <p>
                    CardioMood does not sell, share or rent out data stored in your CardioMood account to third parties,
                    nor
                    does it use them for any purposes other than those outlined in this Privacy Policy.
                </p>
                <p>
                    By using our Services (receiving data from your end-users), you are considered the data controller
                    within the meaning of the GDPR; CardioMood is acting as a data processor. In this capacity, you are
                    responsible in particular for:
                </p>

                <ul>
                    <li>
                        Making all the declarations necessary to the relative data protection authority.
                    </li>
                    <li>
                        Complying with all current regulations in force.
                    </li>
                    <li>
                        Obtaining the explicit consent of the individuals concerned when collecting their personal data.
                    </li>
                    <li>
                        Ensuring your authority to use the personal data collected in accordance with the defined end
                        purposes and refraining from any unauthorized use.
                    </li>
                </ul>

                <p>
                    When using our Services to receive media from EU citizens, it is your obligation to ensure that your
                    entire data pipeline complies with the GDPR regulations. When using our Services to receive media
                    from
                    citizens of the California state (USA), it is your obligation to ensure that your entire data
                    pipeline
                    complies with the CCPA regulations. This implies you should sign (if applicable) the Data Processing
                    Agreement provided by CardioMood to ensure GDPR / CCPA compliance of data flows between you and the
                    Services. Please, find the Data Processing Agreement here.
                </p>


                <H2>
                    DATA SECURITY
                </H2>

                <p>
                    Within the framework of its Services, CardioMood attributes the very highest importance to the
                    security
                    and integrity of information and personal data. CardioMood observes reasonable procedures to prevent
                    unauthorized access to, and the misuse of, information including personal data. We use appropriate
                    business systems and procedures to protect and safeguard information including personal data. We
                    also
                    use security and managerial procedures and industry standard technical restrictions for accessing
                    and
                    using the personal data on our servers. Only authorized personnel are permitted to access personal
                    data
                    in the course of their work.
                </p>
                <p>
                    Thus and in accordance with the GDPR and CCPA, CardioMood undertakes to take all pertinent
                    precautions
                    in order to preserve the security of the data and, in particular, to protect them against any
                    accidental
                    or unlawful destruction, accidental loss, corruption, unauthorized circulation or access, as well as
                    against any other form of unlawful processing or disclosure to unauthorized persons.
                </p>

                <p>
                    To this end, CardioMood implements industry standard security measures to protect personal data from
                    unauthorized disclosure. In using industry recommended methods of encoding, CardioMood takes the
                    measures necessary to protect information connected with payments and credit cards.
                </p>

                <H2>
                    DATA RETENTION
                </H2>

                <p>
                    We will retain your information, which may include personal data, for as long as we deem it
                    necessary to
                    enable you to use our Services, to provide our Services to you, to comply with applicable laws,
                    resolve
                    disputes with any parties and otherwise as necessary to allow us to conduct our business, including
                    to
                    detect and prevent fraud or other illegal activities. All personal data we retain will be subject to
                    this Privacy Policy.
                </p>

                <p>
                    CardioMood in no way undertakes to store all your data indefinitely. You can access data so long as
                    you
                    hold an active account with us and for a period that varies depending on the type of data concerned
                    and
                    the subscribed plan, but, in no event no longer than 12 months after the closing of your account.
                    The
                    data may be deleted at any time during active use of your account in accordance with the provisions
                    set
                    forth above.
                </p>
                <p>
                    If you have a question about a specific retention period for certain types of personal data we
                    process
                    about you, please contact our Data Protection Officer at privacy@cardiomood.com.
                </p>

                <H2>
                    YOUR CHOICES AND RIGHTS
                </H2>

                <p>
                    We want you to be in control of how your personal information is used by us. In accordance with the
                    EU-U.S. and Swiss-U.S. Privacy Shield Frameworks, the European General Data Protection Regulation
                    2016/679 (GDPR), and the California Consumer Privacy Act (CCPA), you can do this in the following
                    ways:
                </p>

                <ul>
                    <li>
                        You can ask us for a copy of the personal information we hold about you.
                    </li>
                    <li>
                        You have the right to know whether your personal information is sold or disclosed and to whom.
                    </li>
                    <li>
                        You have the right to say no to the sale of your personal information.
                    </li>
                    <li>
                        You can inform us of any changes to your personal information, or you can ask us to correct any
                        of
                        the personal information we hold about you. You are also able at any time to modify your
                        personal
                        information by accessing your account settings on our Sites.
                    </li>
                    <li>
                        In certain situations, you can ask us to erase, block, or restrict the processing of the
                        personal
                        information we hold about you or object to particular ways in which we are using your personal
                        information.
                    </li>
                    <li>
                        In certain situations, you can also ask us to send the personal information you have given us to
                        a
                        third party.
                    </li>
                </ul>

                <p>
                    Where we are using your personal information on the basis of your consent, you are entitled to
                    withdraw
                    that consent at any time subject to applicable law. Moreover, where we process your personal
                    information
                    based on legitimate interest or the public interest, you have the right to object at any time to
                    that
                    use of your personal information subject to applicable law.
                </p>

                <p>
                    We rely on you to ensure that your personal information is complete, accurate, and current. Please
                    do
                    inform us promptly of any changes to or inaccuracies of your personal information by contacting
                    help@cardiomood.com. Your applications will be processed within 30 days. We may require your
                    application
                    to be accompanied by a photocopy of proof of identity or authority.
                </p>
                <p>
                    In addition, you have the right to lodge a complaint with the data protection authority in your
                    jurisdiction.
                </p>

                <H2>
                    COOKIES AND TRACKING
                </H2>

                <p>
                    As a general rule, CardioMood uses cookies to improve and personalize its Sites and Services and/or
                    measure its audience. Cookies are files saved to your local storage when browsing on the internet
                    and in
                    particular on our Sites. A cookie is not used to gather your personal data without your knowledge
                    but
                    instead to record information on site browsing which can be read directly by CardioMood on your
                    subsequent visits.
                </p>

                <p>
                    You can choose to decline acceptance of all cookies, but your ability to browse certain pages of our
                    Sites may be reduced. The cookies used by CardioMood are intended to enable or facilitate
                    communication,
                    to enable the Services requested by users to be supplied, to recognize users when they re-visit the
                    site, to secure payments which users may make, or other preferences necessary for the service
                    requested
                    to be supplied and to enable CardioMood, internally, to carry out analyses on hit rates and browsing
                    experience so as to improve content, to track email open rates, click rates, and bounce-back rates
                    at
                    individual levels.
                </p>

                <p>
                    By default, cookies are not installed automatically (except for those cookies needed to run the
                    CardioMood Sites and Services, and you are informed of their installation by a clickable banner with
                    a
                    text description). In accordance with the regulations that apply, CardioMood will require your
                    authorization before implanting any other kind of cookie to your local storage. To avoid being
                    bothered
                    by these routine requests for authorization and to enjoy uninterrupted browsing, you can configure
                    your
                    device to accept CardioMood cookies, or we can remember your refusal or acceptance of certain
                    cookies.
                    By default, browsers accept all cookies.
                </p>

                <p>
                    When you access third party sites on our Sites, or when you are reading integration or social media
                    links, cookies can be created by the companies disseminating these links. These third parties may be
                    able to use cookies in the context of CardioMood’s Services (partners or other third parties
                    supplying content or services available on the CardioMood site) and are responsible for the cookies
                    they install, and it is their conditions on cookies which apply. CardioMood assumes no liability
                    regarding the possible use of cookies by third parties. For more information, you are advised to
                    check the cookie policy directly on these third-party sites concerning their use of cookies.
                </p>

                <H2>
                    PRIVACY POLICY CHANGES
                </H2>

                <p>
                    Just as our business changes constantly, this Privacy Policy may also change from time to time. If
                    you want to see changes made to this Privacy Policy from time to time, we invite you to access this
                    Privacy Policy to see the changes. If we make material changes or changes that will have an impact
                    on you (e.g. when we start processing your personal data for other purposes than set out above), we
                    will contact you prior to commencing that processing.
                </p>

                <p>
                    Any material changes made will be notified to you via our Sites or by email, to the extent possible,
                    three (3) business days at least before any changes come into force.
                </p>

                <H2>
                    PRIVACY SHIELD PRINCIPLES
                </H2>

                <p>
                    CardioMood complies with the Privacy Shield Principles for all onward transfers of personal data
                    from the EU and Switzerland, including the onward transfer liability provisions.
                </p>

                <p>
                    In compliance with the Privacy Shield Principles, CardioMood commits to resolve complaints about our
                    collection or use of your personal information. EU and Swiss individuals with inquiries or
                    complaints regarding our Privacy Shield policy should first contact CardioMood at
                    privacy@cardiomood.com or at our mailing address below:
                </p>

                <p>
                    Chemin du Pré-Fleuri 5, 1228 Plan-les-Ouates, Geneva, Switzerland To: CardioMood SA
                </p>

                <p>
                    In the event we are unable to resolve your concern, you may contact EU Data Protection Authorities
                    for EU/EEA Data Subjects and Swiss Federal Data Protection and Information Commissioner for Swiss
                    Data Subjects which provide an independent third-party dispute resolution body. A binding
                    arbitration option may also be available to you in order to address residual complaints not resolved
                    by any other means.
                </p>

                <p>
                    CardioMood may disclose personal data to trusted third parties as indicated in the Privacy Policy.
                    CardioMood requires that its agents and service providers that have access to Personal Data within
                    the scope of this Privacy Shield Policy provide the same level of protection as required by the
                    Privacy Shield Principles. We ensure that our agents process Personal Data received under the
                    Privacy Shield in a manner consistent with our obligations under the Privacy Shield Principles,
                    unless we prove that we are not responsible for the event giving rise to the damage. We may need to
                    disclose Personal Data in response to lawful requests by public authorities, for law enforcement or
                    national security reasons, or when such action is necessary to comply with a judicial proceeding or
                    court order, or when otherwise required by law.
                </p>

                <H2>
                    GDPR
                </H2>

                <p>
                    CardioMood has further committed to cooperate with the panel established by the EU data protection
                    authorities (DPAs) and the Swiss Federal Data Protection and Information Commissioner (FDPIC) with
                    regard to complaints concerning data transferred from the EU and Switzerland.
                </p>

                <H2>
                    QUESTIONS AND COMPLAINTS
                </H2>

                <p>
                    Please feel free to contact us if you have any questions or complaints about CardioMood’s Privacy
                    Policy or practices. You may contact our Data Protection Officer at privacy@cardiomood.com or at our
                    mailing address below:
                </p>

                <p>
                    Chemin du Pré-Fleuri 5, 1228 Plan-les-Ouates, Geneva, Switzerland To: CardioMood SA
                </p>
            </Wrapper>
        </MainTemplate>
    );
}

const Wrapper = styled.div`

`;

const H1 = styled.h1`
    font-size: 24px;
`;


const H2 = styled.h2`
    font-size: 18px;
`;

