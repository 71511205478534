import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

export default function UnderConstructionTool(props) {


    return (
        <Wrapper>
            <p>
                This page is under construction.
            </p>
            <p>
                Sabir will finish that
            </p>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    font-size: 18px;
`;